import React, { Component, startTransition } from 'react';
import PropTypes from 'prop-types';
import { isPathnameChanged, venomHistory } from 'client/utils/history/venom-history';
import classNames from 'classnames';
import { scroller } from 'react-scroll'; // eslint-disable-line no-restricted-syntax
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { remove, isEqual, noop, compact } from 'lodash';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Input from 'reactstrap/lib/Input';
import {
  fireViewSearchResult,
  fireSelectAutocomplete,
  fireAbandonSearchResult,
} from 'client/engagement-handlers/home-engagement-handler/home-engagement-handler';
import { MAX_SEARCH_TEXT_LENGTH } from 'client/data/models/auto-suggest';
import { HomeVehicleSearchAutoCompleteMulti } from 'site-modules/shared/components/home-vehicle-search-autocomplete/home-vehicle-search-autocomplete';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { Storage } from 'site-modules/shared/utils/storage';
import { DATA_TYPE } from 'site-modules/shared/constants/home-vehicle-search-constants';
import { getYearValue } from 'site-modules/shared/utils/vehicle-search-utils';

import './home-vehicle-search.scss';

const SCROLL_PARAMS = {
  duration: 600,
  smooth: true,
  isDynamic: true,
  offset: -10,
};
const BODY_CLASS = 'auto-complete-dropdown-open';
const DEFAULT_SCROLL_TO_ANCHOR = 'search-box-input';
const DEFAULT_OPENED_ADDON_CLASS = 'input-button px-1_25 py-1_25 bg-white border-0 text-info';
export const AUTO_COMPLETE_HISTORY_KEY = 'autoCompleteHistory';

export const UNFILTERED_SRP_LINK = '/inventory/srp.html';
export const DEBOUNCE_TIMEOUT = 250;
export const THROTTLE_TIMEOUT = 300;

export const isFirstLetterOfWord = query => query.length === 1 || query.charAt(query.length - 2) === ' ';

export const getSearchHistoryAutoSuggestValue = search => {
  const { make, model, type, autoSuggestValueFull } = search;
  if (make && model) {
    const year = getYearValue(search);
    return compact([year, make, model]).join(' ');
  }
  if (make) return make;
  if (type) return type;
  return autoSuggestValueFull;
};

export const convertResultsToString = res =>
  res
    .map(s => s.autoSuggestValueFull)
    .join(',')
    .slice(0, 500);

export const getAutoCompleteResultsList = searches => ({
  autocompleteResults: convertResultsToString(
    searches.filter(s => s.dataType === DATA_TYPE.LABEL || s.dataType === DATA_TYPE.STATIC_LINK)
  ),
  autocompleteLinks: convertResultsToString(searches.filter(s => s.dataType !== DATA_TYPE.LABEL)),
});

/**
 * Removal business logic.
 * 1. Removes a "search-history" search that is from from existing history to prevent duplicates.
 * 2. Removes existing make model search so it holds only one make model search of core, used, or review.
 *
 * @param autoCompleteSearchHistory
 * @param search
 */
export const autoCompleteSearchHistoryRemoval = (autoCompleteSearchHistory, search) => {
  const existingSearch = autoCompleteSearchHistory.find(s => isEqual(search, s));
  const existingMakeModelOrTypeSearch = autoCompleteSearchHistory.find(
    s => (s.make && s.make === search.make && s.model === search.model) || (s.type && s.type === search.type)
  );

  if (existingSearch) {
    remove(autoCompleteSearchHistory, existingSearch);
  }

  if (existingMakeModelOrTypeSearch) {
    remove(autoCompleteSearchHistory, existingMakeModelOrTypeSearch);
  }
};

/**
 * Add(prepend, unshift, always first index) search to search history.
 * Also calls a removal search logic function within the method.
 * @param search
 */
export const getAutoCompleteSearchHistory = (search, autoCompleteSearchHistory) => {
  const historySearch = search;
  delete historySearch.index;
  autoCompleteSearchHistoryRemoval(autoCompleteSearchHistory, historySearch);
  if (autoCompleteSearchHistory.length >= 5) autoCompleteSearchHistory.pop();
  autoCompleteSearchHistory.unshift(historySearch);
  return autoCompleteSearchHistory;
};

export class HomeVehicleSearch extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    placeholder: PropTypes.string,
    trackingCreativeId: PropTypes.string,
    wrapperClasses: PropTypes.string,
    closeClassName: PropTypes.string,
    searchBoxClasses: PropTypes.string,
    searchInputClasses: PropTypes.string,
    addonClasses: PropTypes.shape({
      closed: PropTypes.string,
      opened: PropTypes.string,
      submitted: PropTypes.string,
    }),
    onDropDownOpen: PropTypes.func,
    onDropDownClose: PropTypes.func,
    scrollToAnchorOverride: PropTypes.string,
    onCrossClick: PropTypes.func,
    pathname: PropTypes.string,
  };

  static defaultProps = {
    isMobile: false,
    trackingCreativeId: 'edm-entry-dealer-inventory',
    placeholder: 'Enter Make or Model',
    addonClasses: {
      closed: 'icon-search2 input-button px-1_25 px-lg-2_5 py-1 mr-1 bg-success border-0 text-white',
      opened: `icon-cross2 ${DEFAULT_OPENED_ADDON_CLASS}`,
      submitted: `submitted d-flex justify-content-center align-items-center ${DEFAULT_OPENED_ADDON_CLASS}`,
    },
    wrapperClasses: 'ed-zdepth-2 rounded',
    closeClassName: '',
    searchBoxClasses: 'mt-0_25 w-100',
    searchInputClasses: 'p-0 pl-0_25 py-0_5 bg-white',
    onDropDownOpen: noop,
    onDropDownClose: noop,
    scrollToAnchorOverride: DEFAULT_SCROLL_TO_ANCHOR,
    onCrossClick: noop,
    pageName: null,
    selectedFacets: {},
    pathname: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      isDropdownOpen: false,
      searchQuery: '',
      submitted: null,
      autoCompleteSearchHistory: null,
      shouldFireAbandonSearchTracking: true,
      isMounted: false,
    };
    this.clickEvents = ['touchend', 'click', 'focusout'];
    this.searchesList = {};

    this.starSearchingDebounced = debounce(this.startSearching, DEBOUNCE_TIMEOUT);
    this.starSearchingThrottled = throttle(this.startSearching, THROTTLE_TIMEOUT);
    this.startSearchingAsync = this.starSearchingThrottled;
    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
    this.storage = new Storage('localStorage');
    this.setAutoCompleteSearchHistory();
    this.clickEvents.forEach(event => document.addEventListener(event, this.handleOutside));
    this.setMounted();
  }

  componentWillUnmount() {
    this.clickEvents.forEach(event => document.removeEventListener(event, this.handleOutside));
  }

  onChange = e => {
    const {
      target: { value: query },
    } = e;

    if (query.length < 5 || isFirstLetterOfWord(query)) {
      this.starSearchingDebounced.cancel();
      this.startSearchingAsync = this.starSearchingThrottled;
    } else {
      this.startSearchingAsync = this.starSearchingDebounced;
    }

    this.setState({ query }, () => {
      this.startSearchingAsync(query);
    });
  };

  onSubmitHandler = (url = UNFILTERED_SRP_LINK, results = {}) => {
    const { search = {}, searches = [] } = results;
    const { query } = this.state;
    const { pathname } = this.props;
    const isPathChanged = isPathnameChanged(pathname, url);
    const searchKeys = Object.keys(search);
    const selectionType = search.selectionType || 'make model';
    const { autocompleteResults, autocompleteLinks } = getAutoCompleteResultsList(searches);

    const trackingData = {
      input: query.substring(0, 50).toLowerCase(),
      trackingValue: searchKeys.length ? search.autoSuggestValueFull : query,
      creativeId: this.props.trackingCreativeId,
      selectionType,
      autocompleteResults,
      autocompleteLinks,
    };

    const autoCompleteSearchHistory = getAutoCompleteSearchHistory(
      {
        ...search,
        autoSuggestValue: getSearchHistoryAutoSuggestValue(search),
        dataType: DATA_TYPE.SEARCH_HISTORY,
        selectionType: DATA_TYPE.SEARCH_HISTORY,
      },
      this.state.autoCompleteSearchHistory || []
    );
    this.storage.set(AUTO_COMPLETE_HISTORY_KEY, autoCompleteSearchHistory);

    this.setState(
      { submitted: isPathChanged, query: isPathChanged ? search.autoSuggestValueFull : '', isDropdownOpen: false },
      () => {
        fireViewSearchResult(trackingData);
        if (!isPathChanged) this.props.onDropDownClose();
        venomHistory.push(url);
      }
    );
  };

  setSearchInputRef = ref => {
    this.searchInputRef = ref;
  };

  setSearchesTrackingData = searches => {
    // Set to class field instead of state to prevent unnecessary component's re-render
    this.searchesList = getAutoCompleteResultsList(searches);
  };

  /**
   * Sets "shouldFireAbandonSearchTracking" based off of the search results. MEM-3012.
   */
  setFireAbandonSearchTracking = shouldFire => this.setState({ shouldFireAbandonSearchTracking: shouldFire });

  /**
   * Sets "autoCompleteSearchHistory" state with the value from localStorage item (local storage key: autoCompleteHistory)
   * @returns setState function
   */
  setAutoCompleteSearchHistory = () =>
    this.setState({ autoCompleteSearchHistory: this.storage.get(AUTO_COMPLETE_HISTORY_KEY) });

  setMounted = () => this.setState({ isMounted: true });

  handleOutside = event => {
    const isSearchElementClick = event.target.closest('.home-vehicle-search-box');
    if (this.state.isDropdownOpen && !isSearchElementClick) {
      this.closeDropDown();
    }
  };

  /**
   * The "query" state is already set(in the "onChange" function) so it can access from the "query" from prevState to set it in sync.
   */
  startSearching = searchQuery =>
    this.setState(({ query }) => ({ searchQuery: searchQuery ? searchQuery.trim() : query.trim() }));

  fireAbandonTracking = () => {
    const { autocompleteResults, autocompleteLinks } = this.searchesList;
    const trackingData = {
      input: this.state.query.substring(0, 50).toLowerCase(),
      creativeId: this.props.trackingCreativeId,
      autocompleteResults,
      autocompleteLinks,
    };

    if (!this.state.shouldFireAbandonSearchTracking) {
      return;
    }

    fireAbandonSearchResult(trackingData);
  };

  closeDropDown = () => {
    this.searchInputRef.blur();
    document.body.classList.remove(BODY_CLASS);
    this.setState({ isDropdownOpen: false }, () => {
      this.fireAbandonTracking();
      this.props.onDropDownClose();
    });
  };

  handleCrossClick = () => {
    document.body.classList.remove(BODY_CLASS);
    this.setState({ query: '', searchQuery: '', isDropdownOpen: false }, () => {
      setTimeout(() => {
        this.fireAbandonTracking();
      });

      this.props.onCrossClick();
    });
  };

  handleInputClick = () => {
    const trackingPlaceholderValue = this.props.placeholder.replace(/[,."]/g, '');
    setTimeout(() => {
      fireSelectAutocomplete(this.props.trackingCreativeId, trackingPlaceholderValue);
    });

    if (this.props.isMobile) {
      scroller.scrollTo(this.props.scrollToAnchorOverride, SCROLL_PARAMS);
    }
  };

  openDropDown = () => {
    document.body.classList.add(BODY_CLASS);
    startTransition(() => {
      this.setState({ isDropdownOpen: true }, () => {
        this.props.onDropDownOpen();
      });
    });
  };

  handleButtonClick = () => {
    this.handleInputClick();
    this.searchInputRef.focus();
  };

  renderAddon = () => {
    const { addonClasses } = this.props;
    const { query, isDropdownOpen, submitted } = this.state;

    if (submitted) {
      return (
        <InputGroupAddon addonType="prepend" className="p-0 border-0 bg-white">
          <div className={addonClasses.submitted}>
            <Spinner size={14} thickness={1} color="info" className="mr-0_5 align-middle" />
          </div>
        </InputGroupAddon>
      );
    }

    return query || isDropdownOpen ? (
      <InputGroupAddon addonType="prepend" className="p-0 border-0 bg-white">
        <button onClick={this.handleCrossClick} className={addonClasses.opened} aria-label="Clear Search" />
      </InputGroupAddon>
    ) : (
      <InputGroupAddon addonType="prepend" className="p-0 border-0 bg-white">
        <button onClick={this.handleButtonClick} className={addonClasses.closed} aria-label="Search" />
      </InputGroupAddon>
    );
  };

  render() {
    const {
      placeholder,
      wrapperClasses,
      searchBoxClasses,
      searchInputClasses,
      trackingCreativeId,
      isMobile,
      closeClassName,
    } = this.props;
    const { query, isDropdownOpen, searchQuery, autoCompleteSearchHistory, isMounted } = this.state;

    return (
      <div className={classNames(wrapperClasses, { [closeClassName]: !isDropdownOpen })}>
        <div
          className={classNames('home-vehicle-search-box text-start mx-auto pos-r', searchBoxClasses, {
            'show-dropdown': isDropdownOpen,
          })}
          data-tracking-parent={trackingCreativeId}
        >
          <div className="search-box-frame h-100 w-100" />
          <div className="search-wrapper pos-r">
            <ScrollElement id={DEFAULT_SCROLL_TO_ANCHOR}>
              <InputGroup className="search-box-input-group bg-white">
                {this.renderAddon()}
                <Input
                  className={classNames('search-box border-0', searchInputClasses)}
                  onClick={this.handleInputClick}
                  placeholder={placeholder}
                  value={query}
                  onChange={this.onChange}
                  onFocus={this.openDropDown}
                  innerRef={this.setSearchInputRef}
                  autoComplete="off"
                  name="search-input"
                  aria-label="Enter Make or Model"
                  disabled={!isMounted}
                  maxLength={MAX_SEARCH_TEXT_LENGTH}
                />
              </InputGroup>
            </ScrollElement>
            <HomeVehicleSearchAutoCompleteMulti
              closeDropDown={this.closeDropDown}
              searchQuery={searchQuery}
              isDropdownOpen={isDropdownOpen}
              isMobile={isMobile}
              onSubmitHandler={this.onSubmitHandler}
              asyncStartSearching={this.startSearchingAsync}
              startSearching={this.startSearching}
              trackingCreativeId={trackingCreativeId}
              searchHistory={autoCompleteSearchHistory}
              setFireAbandonSearchTracking={this.setFireAbandonSearchTracking}
              setSearchesTrackingData={this.setSearchesTrackingData}
            />
          </div>
        </div>
      </div>
    );
  }
}
