import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { UserProfileModel } from 'client/data/models/profile/profile';
import { connect } from 'react-redux';

import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

import { Storage } from 'site-modules/shared/utils/storage';
import { onAuthStateChanged } from 'client/site-modules/shared/components/profile/firebase-auth';
import { getAlertsPath } from 'site-modules/shared/components/profile/price-drop-alerts/alerts-utils';

import './price-drop-alerts.scss';

export const LAST_ALERTS = 'lastAlerts';
const MAX_ALERT_COUNT = 9;

export class PriceDropAlertsComponent extends Component {
  static propTypes = {
    priceDropAlerts: PropTypes.shape({
      alerts: PropTypes.arrayOf(PropTypes.shape()),
      newAlerts: PropTypes.arrayOf(PropTypes.shape()),
      newAlertsCount: PropTypes.number,
    }),
    hideAlertsPerSession: PropTypes.bool,
    hideCount: PropTypes.bool,
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    priceDropAlerts: {},
    isAuthenticated: false,
    hideAlertsPerSession: false,
    hideCount: false,
    className: '',
    isMobile: false,
    style: null,
  };

  state = {
    newAlertsCount: 0,
    newAlerts: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      priceDropAlerts,
      priceDropAlerts: { alerts },
    } = nextProps;

    if (!alerts || !window) {
      return null;
    }

    // Triggers re-render once after storage initialization
    const storage = prevState.storage || new Storage('sessionStorage');
    return { ...priceDropAlerts, storage };
  }

  componentDidMount() {
    onAuthStateChanged(user => {
      if (!user) {
        this.removeLocalStorageValue();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { isMobile, hideCount, hideAlertsPerSession } = this.props;
    const { newAlertsCount } = this.state;
    const hasNewAlerts = this.userHasNewAlerts();

    if (hasNewAlerts) {
      this.removeLocalStorageValue();
    }

    if (!prevProps.hideAlertsPerSession && hideAlertsPerSession) {
      this.updateLocalStorageValue();
    }

    if (newAlertsCount && !hideCount && hasNewAlerts && !this.globalNavTrackingFired) {
      EventToolbox.fireTrackAction({
        event_type: 'action_completed',
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          subaction_name: TrackingConstant.SHOW_NOTIFICATION_COUNT,
          action_cause: 'page_load',
          action_category: 'system',
          creative_id: isMobile ? 'nav-drawer' : 'edm-entry-global-nav',
          value: newAlertsCount,
        },
      });
      this.globalNavTrackingFired = true;
    }
  }

  getLocalStorageValue = (key = LAST_ALERTS) => {
    const { storage } = this.state;
    if (!storage) {
      return true;
    }
    return storage.get(key);
  };

  globalNavTrackingFired = false;

  userHasNewAlerts = () => {
    const { newAlerts } = this.state;
    return newAlerts && !isEqual(newAlerts, this.getLocalStorageValue());
  };

  updateLocalStorageValue = () => {
    const { newAlerts, storage } = this.state;
    if (storage) {
      storage.set(LAST_ALERTS, newAlerts);
    }
  };

  removeLocalStorageValue = () => {
    const { storage } = this.state;
    if (storage) {
      storage.remove(LAST_ALERTS);
    }
  };

  render() {
    const { className, hideAlertsPerSession, hideCount, style } = this.props;
    const { newAlertsCount } = this.state;
    const hasAlerts = this.userHasNewAlerts();
    const hideAlerts = hideCount ? false : hideAlertsPerSession || !hasAlerts;

    return (
      !!newAlertsCount &&
      !hideAlerts && (
        <span
          className={classNames('alerts-container fw-bold text-white text-center d-inline-block', className, {
            'without-count': hideCount,
          })}
          style={style}
        >
          {!hideCount && (
            <Fragment>{newAlertsCount > MAX_ALERT_COUNT ? `${MAX_ALERT_COUNT}+` : newAlertsCount}</Fragment>
          )}
        </span>
      )
    );
  }
}

export const stateToPropsConfig = {
  priceDropAlerts: bindToPath(
    ({ isAuthenticated }) => (isAuthenticated ? getAlertsPath('ALL', true) : ''),
    UserProfileModel
  ),
};

export const mapStateToProps = state => ({
  isAuthenticated: state.profile.isAuthenticated,
  isMobile: state.mobile,
});

export const PriceDropAlerts = connect(mapStateToProps)(connectToModel(PriceDropAlertsComponent, stateToPropsConfig));
