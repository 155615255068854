import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { escapeRegExp } from 'lodash';
import { findAll } from 'highlight-words-core';
import { DATA_TYPE } from 'site-modules/shared/constants/home-vehicle-search-constants';

export function AutoCompleteOptionHighlightedLabel({ search, query }) {
  const { autoSuggestValue, dataType } = search;

  const textToHighlight = search.autoSuggestValue;
  const searchWords = escapeRegExp(query)
    .split(/\s/)
    .filter(word => word);
  const chunks = findAll({ searchWords, textToHighlight });

  return (
    <div
      className={classNames({
        'px-1 pt-0_5 text-gray-darker': dataType !== DATA_TYPE.STATIC_LINK,
        value: dataType === DATA_TYPE.STATIC_LINK,
      })}
    >
      <div className="fw-bold">
        {chunks.map((chunk, index) => {
          const text = autoSuggestValue.substr(chunk.start, chunk.end - chunk.start);
          const key = `${text}${index}`;

          return chunk.highlight || dataType === DATA_TYPE.STATIC ? (
            <span className="fw-normal" key={key}>
              {text}
            </span>
          ) : (
            <span key={key}>{text}</span>
          );
        })}
      </div>
    </div>
  );
}

AutoCompleteOptionHighlightedLabel.propTypes = {
  search: PropTypes.shape({
    autoSuggestValue: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  query: PropTypes.string.isRequired,
};

export function AutoCompleteOptionLabel({ label }) {
  return <div className="px-1 pt-0_5 text-gray-darker">{label}</div>;
}

AutoCompleteOptionLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export function NoMatchesFoundLabel({ label }) {
  return <div className="px-1 py-0_5 my-0 px-1_5 size-16 text-gray-darker">{label}</div>;
}

NoMatchesFoundLabel.propTypes = {
  label: PropTypes.string.isRequired,
};
