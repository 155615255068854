import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { PAGE_NAMES } from 'site-modules/shared/constants/multi-offer/offers';
import { CBP_LANDING, TOP_RATED } from 'site-modules/shared/constants';
import { JoinInsider } from 'site-modules/shared/components/profile/join-insider/join-insider-sign-up';
import { AppDownload } from 'site-modules/shared/components/app-download/app-download';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

import { LINKS, SOCIAL_LINKS, LINKS_W_FOR_DEALERS, FOR_DEALERS_LINK, SOCIAL_LINKS_ADS_10216 } from './footer-data';
import './footer.scss';

const REMOVE_NO_FOLLOW_BY_PAGE_NAME = ['inventory_comparator'];
const excludesJoinInsiderByPageName = pageName =>
  ![
    CBP_LANDING,
    PAGE_NAMES.OFFER_RESULTS_NOT_FOUND,
    PAGE_NAMES.MULTI_OFFER_RESULTS,
    PAGE_NAMES.OFFER_DETAILS_PAGE,
    PAGE_NAMES.APPRAISER_OFFER_RENEWAL,
  ].includes(pageName);
const CCPA_URL = '/ccpa/';

function renderFooterLinks(removeNoFollow, includeForDealers, isAds10216Chal) {
  const footerLinks = includeForDealers ? LINKS_W_FOR_DEALERS : LINKS;

  return footerLinks.map(({ href, text }) => {
    const forceNoFollow = CCPA_URL === href || FOR_DEALERS_LINK.href === href;

    return (
      <li
        className={classnames('global-footer-link small', {
          'mb-1 mb-md-0': isAds10216Chal,
        })}
        key={text}
        style={{ marginRight: '28px', lineHeight: '24px' }}
      >
        <a href={href} rel={removeNoFollow && !forceNoFollow ? undefined : 'nofollow'}>
          {text}
        </a>
      </li>
    );
  });
}

export function renderSocialLinks(isAds10216Chal) {
  const socialLinksToUse = isAds10216Chal ? SOCIAL_LINKS_ADS_10216 : SOCIAL_LINKS;
  return socialLinksToUse.map(({ href, title, icon }) => (
    <li
      key={title}
      className={classnames({
        'mr-1': !isAds10216Chal,
        'mr-1_5': isAds10216Chal,
      })}
    >
      <a href={href} title={title} aria-label={title} className="d-inline-block">
        <span className={`${icon} social-link-icon`} aria-hidden />
      </a>
    </li>
  ));
}

function FooterUI({ isHomepage, pageName, isMobile, isAds10216Enabled }) {
  const shouldShowInsider = excludesJoinInsiderByPageName(pageName) && (pageName && !pageName.includes(TOP_RATED));
  const removeNoFollow = isHomepage || REMOVE_NO_FOLLOW_BY_PAGE_NAME.includes(pageName);

  return (
    <div
      className={classnames('global-footer text-gray-darker', {
        'pos-r': isAds10216Enabled,
      })}
      data-tracking-parent="edm-entry-global-footer"
    >
      <Experiment name="ads-10216" showDefault>
        <Recipe name="ctrl" isDefault />
        <Recipe name="chal">
          <div className="pos-a w-100 h-100 ads-10216-bg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="pointer-events-none hidden-md-up pos-a h-100 w-100"
              preserveAspectRatio="none"
              viewBox="0 0 375 826"
            >
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M0 1013.66h375V0a2111.167 2111.167 0 0 1-17.656 4.943C234.694 35.694 113.879 54.29 0 59.513v954.147Z"
                clipRule="evenodd"
              />
              <path
                fill="url(#lin-grad-sm)"
                fillOpacity=".7"
                fillRule="evenodd"
                d="M0 1013.66h375V0a2111.167 2111.167 0 0 1-17.656 4.943C234.694 35.694 113.879 54.29 0 59.513v954.147Z"
                clipRule="evenodd"
              />
              <defs>
                <linearGradient
                  id="lin-grad-sm"
                  x1={40.781}
                  x2={398.923}
                  y1={464.504}
                  y2={471.09}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F0F6FF" />
                  <stop offset=".495" stopColor="#F0FDFF" />
                  <stop offset={1} stopColor="#F8F0FF" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="pointer-events-none hidden-sm-down pos-a h-100 w-100"
              preserveAspectRatio="none"
              viewBox="0 0 1200 487"
            >
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M-200 67.763c362.569 66.46 778.038 68.651 1209.44 11.08C1143.06 57.825 1273.61 31.724 1400 .72V632H-200V67.763Z"
                clipRule="evenodd"
              />
              <path
                fill="url(#lin-grad-lg)"
                fillOpacity=".4"
                fillRule="evenodd"
                d="M-200 67.763c362.569 66.46 778.038 68.651 1209.44 11.08C1143.06 57.825 1273.61 31.724 1400 .72V632H-200V67.763Z"
                clipRule="evenodd"
              />
              <defs>
                <linearGradient
                  id="lin-grad-lg"
                  x1={-337}
                  x2={652.995}
                  y1={-35.5}
                  y2={1121.18}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#C2F8FF" />
                  <stop offset=".566" stopColor="#F0F6FF" />
                  <stop offset={1} stopColor="#FFF0F1" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </Recipe>
      </Experiment>
      {shouldShowInsider && (
        <Experiment name="ads-10216" showDefault>
          <Recipe name="ctrl" isDefault>
            <JoinInsider className="pt-2 pb-1_5" />
          </Recipe>
          <Recipe name="chal">
            <JoinInsider className="pb-2" isAds10216Chal />
          </Recipe>
        </Experiment>
      )}
      <div className={classnames('footer-main container-fluid bg-faded pt-2 pb-3')}>
        <footer className="container">
          <Experiment name="ads-10216" showDefault>
            <Recipe name="ctrl" isDefault>
              <Fragment>
                <ul className="social-links list-unstyled mb-1_5 d-flex">{renderSocialLinks()}</ul>
                <AppDownload className="mt-2 mb-2" />
                <ul className="global-links mb-0_75 row flex-column flex-md-row flex-wrap list-unstyled px-0_5">
                  {renderFooterLinks(removeNoFollow, isHomepage)}
                </ul>
              </Fragment>
            </Recipe>
            <Recipe name="chal">
              <div className="pb-2 overflow-hidden">
                <div className="float-md-left">
                  <h2 className="mb-0_75 heading-5 fw-medium">Follow Us</h2>
                  <ul className="social-links list-unstyled mb-2 mb-md-0 d-flex is-ads-10216-chal">
                    {renderSocialLinks(true)}
                  </ul>
                </div>
                <div className="float-md-right">
                  <AppDownload isAds10216Chal />
                </div>
              </div>
              <ul className="global-links mb-2 mb-md-0_75 row flex-column flex-md-row flex-wrap list-unstyled px-0_5 pb-0_25 pb-md-0">
                {renderFooterLinks(removeNoFollow, isHomepage, true)}
              </ul>
            </Recipe>
          </Experiment>

          <div className="copyright small text-gray-darker" style={{ marginBottom: isMobile ? '40px' : '100px' }}>
            <a href="/">&copy; Edmunds.com, Inc.</a>, a wholly owned subsidiary of CarMax, Inc. By using edmunds.com,
            you consent to the monitoring and storing of your interactions with the website, including by an Edmunds
            vendor, for use in improving and personalizing our services. See our{' '}
            <a href="/about/privacy.html">Privacy Statement</a> for details.
          </div>
        </footer>
      </div>
    </div>
  );
}

FooterUI.propTypes = {
  isHomepage: PropTypes.bool,
  isMobile: PropTypes.bool,
  pageName: PropTypes.string,
  isAds10216Enabled: PropTypes.bool,
};

FooterUI.defaultProps = {
  isHomepage: false,
  isMobile: false,
  pageName: null,
  isAds10216Enabled: false,
};

const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
  pageName: get(state, 'pageContext.page.name'),
  isAds10216Enabled:
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'ads-10216',
      defaultVal: 'ctrl',
    }) === 'chal',
});

export const Footer = connect(mapStateToProps)(FooterUI);
