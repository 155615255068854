import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { URL_PATHS } from 'site-modules/shared/constants/appraisal/paths';

const GENERAL_LINKS = [
  {
    titlePostfix: 'Car Pricing',
    title: 'New',
    trackingId: 'global_nav_select_new_car',
    url: '/new-cars/',
  },
  {
    titlePostfix: 'Cars for Sale',
    title: 'Used',
    trackingId: 'global_nav_select_used_car',
    url: '/used-cars-for-sale/',
  },
];

const SEOT_4353_LINKS = [
  {
    titlePostfix: 'Cars for Sale',
    title: 'New',
    trackingId: 'global_nav_select_new_car',
    url: '/new-cars/',
  },
  {
    titlePostfix: 'Cars for Sale',
    title: 'Used',
    trackingId: 'global_nav_select_used_car',
    url: '/used-cars-for-sale/',
  },
];

const HOME_LINK = {
  title: 'Home',
  trackingId: 'global_nav_homepage_click',
  url: '/',
};

const APPRAISAL_LINK = {
  titlePostfix: 'My Car',
  title: 'Appraise',
  trackingId: 'appraisal_link_tool_select',
  url: URL_PATHS.APPRAISAL_LANDING,
  attributes: {
    'data-tracking-value': 'Appraise My Car',
  },
};

const REVIEW_LINK = {
  titlePrefix: 'Car',
  title: 'Reviews',
  trackingId: 'link_select_tool_select',
  url: '/car-reviews/',
};

function GlobalNavLink({ link, pathname, displayFullLinksText }) {
  const { titlePostfix = '', title, titlePrefix = '' } = link;
  const alwaysDisplayFullLinkText = displayFullLinksText && link.url === APPRAISAL_LINK.url;

  return (
    <NavItem className="py-0_25 py-md-0" key={link.url}>
      <NavLink
        href={link.url}
        className="px-0 px-md-1"
        data-tracking-id={link.trackingId}
        active={pathname === link.url}
        {...link.attributes}
      >
        <span className="global-nav-link text-shadow-contrast-high">
          {alwaysDisplayFullLinkText ? (
            `${titlePrefix} ${title} ${titlePostfix}`.trim()
          ) : (
            <Fragment>
              {!!titlePrefix && <span className="d-none d-lg-inline">{`${titlePrefix} `}</span>}
              {title}
              {!!titlePostfix && <span className="d-none d-lg-inline">{` ${titlePostfix}`}</span>}
            </Fragment>
          )}
        </span>
      </NavLink>
    </NavItem>
  );
}

GlobalNavLink.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string,
    trackingId: PropTypes.string,
    titlePostfix: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  pathname: PropTypes.string,
  isMobile: PropTypes.bool,
};

GlobalNavLink.defaultProps = {
  pathname: '',
  isMobile: true,
};

function getLinksToRender({
  renderGeneralLinks,
  renderHomeLink,
  renderAppraisalLink,
  reverseAppendedLinks,
  isSEOT4353Enabled,
}) {
  const generalLinks = renderGeneralLinks ? [...GENERAL_LINKS] : [];
  let links = isSEOT4353Enabled ? [...SEOT_4353_LINKS] : generalLinks;

  if (renderHomeLink) {
    links = [HOME_LINK, ...links];
  }
  const linksToAppend = [REVIEW_LINK];
  if (renderAppraisalLink) {
    linksToAppend.push(APPRAISAL_LINK);
  }

  if (!reverseAppendedLinks) linksToAppend.reverse();

  return [...links, ...linksToAppend];
}

export const NavigationLinks = memo(
  ({
    renderHomeLink,
    renderGeneralLinks,
    pathname,
    className,
    renderAppraisalLink,
    displayFullLinksText,
    isMobile,
  }) => (
    <div className={classnames({ 'w-100': !!className })}>
      <FeatureFlag name="enable-seot-4353-global-nav-update">
        {enabled =>
          enabled ? (
            <Nav className={classnames('navigation-links m-0 p-0', className)}>
              {getLinksToRender({
                renderGeneralLinks,
                renderHomeLink,
                renderAppraisalLink,
                reverseAppendedLinks: isMobile,
                isSEOT4353Enabled: true,
              }).map(link => (
                <GlobalNavLink
                  link={link}
                  pathname={pathname}
                  key={link.url}
                  displayFullLinksText={displayFullLinksText}
                />
              ))}
            </Nav>
          ) : (
            <Nav className={classnames('navigation-links m-0 p-0', className)}>
              {getLinksToRender({
                renderGeneralLinks,
                renderHomeLink,
                renderAppraisalLink,
                reverseAppendedLinks: isMobile,
                isSEOT4353Enabled: undefined,
              }).map(link => (
                <GlobalNavLink
                  link={link}
                  pathname={pathname}
                  key={link.url}
                  displayFullLinksText={displayFullLinksText}
                />
              ))}
            </Nav>
          )
        }
      </FeatureFlag>
    </div>
  )
);

NavigationLinks.propTypes = {
  pathname: PropTypes.string,
  renderHomeLink: PropTypes.bool,
  renderGeneralLinks: PropTypes.bool,
  className: PropTypes.string,
  renderAppraisalLink: PropTypes.bool,
  displayFullLinksText: PropTypes.bool,
  isMobile: PropTypes.bool,
};

NavigationLinks.defaultProps = {
  pathname: '',
  renderHomeLink: false,
  renderGeneralLinks: true,
  className: '',
  renderAppraisalLink: true,
  displayFullLinksText: false,
  isMobile: false,
};

NavigationLinks.displayName = 'NavigationLinks';
