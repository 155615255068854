import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { GlobalNavigation } from 'site-modules/shared/components/global-navigation-v2/global-navigation';
import { Footer } from 'site-modules/shared/components/footer/footer';
import { StickyBanner } from 'site-modules/shared/components/sticky-banner/sticky-banner';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { SpaLinks } from 'site-modules/shared/components/spa-links/spa-links';
import { ProfileContextAsync } from 'site-modules/shared/components/profile/profile-context-async';
import { ExperimentsPanel } from 'site-modules/shared/components/experiment/panel/experiments-panel';
import { AdsContext } from 'site-modules/shared/components/ads-context/ads-context';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { ErrorPageMessage } from 'site-modules/shared/components/error-page-message/error-page-message';
import { PubpixelAd } from 'site-modules/shared/components/pubpixel/pubpixel-ad';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { VisitorModel } from 'client/data/models/visitor';
import { SEOHead } from 'site-modules/shared/pages/seo';
import { FloodlightAd } from 'site-modules/shared/components/floodlight/floodlight-ad';
import { SkipToMainContent } from 'site-modules/shared/components/skip-to-main-content/skip-to-main-content';

export class EdmundsDefaultDecoratorUI extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isHome: PropTypes.bool,
    skipFloodlight: PropTypes.bool,
    isMobile: PropTypes.bool,
    showSearchBar: PropTypes.bool,
  };

  static defaultProps = {
    isHome: false,
    skipFloodlight: false,
    isMobile: false,
    showSearchBar: true,
  };

  static preload(store) {
    const preloader = new ModelPreloader(store);
    preloader.resolve('segment', VisitorModel);
    preloader.resolve('rttSegment', VisitorModel);

    return preloader.load();
  }

  render() {
    const { isHome, skipFloodlight, children, isMobile, showSearchBar } = this.props;

    return (
      <div
        className={classnames('edm-page has-global-nav', {
          'no-search': isHome && !isMobile,
          desktop: !isMobile,
        })}
      >
        <ErrorBoundary>
          <SkipToMainContent />
          <GlobalNavigation showSearchBar={showSearchBar} />
        </ErrorBoundary>
        <ErrorBoundary>
          <SEOHead {...this.props} />
        </ErrorBoundary>
        <ErrorBoundary>
          <FeatureFlag name="testSpa">
            <ErrorBoundary>
              <SpaLinks />
            </ErrorBoundary>
          </FeatureFlag>
        </ErrorBoundary>
        <ErrorBoundary message={ErrorPageMessage}>{children}</ErrorBoundary>
        <ErrorBoundary>
          <Footer isHomepage={isHome} />
        </ErrorBoundary>
        <ErrorBoundary>
          <FeatureFlag name="ads">
            <ErrorBoundary>
              <StickyBanner isHomepage={isHome} />
            </ErrorBoundary>
            <ErrorBoundary>
              <PubpixelAd />
            </ErrorBoundary>
            <ErrorBoundary>{!skipFloodlight && <FloodlightAd />}</ErrorBoundary>
            <ErrorBoundary>
              <AdsContext />
            </ErrorBoundary>
          </FeatureFlag>
        </ErrorBoundary>
        <ErrorBoundary>
          <FeatureFlag name="insider">
            <ProfileContextAsync />
          </FeatureFlag>
        </ErrorBoundary>
        <ErrorBoundary>
          <ClientSideRender>
            <ExperimentsPanel />
          </ClientSideRender>
        </ErrorBoundary>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const EdmundsDefaultDecorator = connect(mapStateToProps)(EdmundsDefaultDecoratorUI);
