import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { ENTER_KEY_VALUE } from 'site-modules/shared/constants/key-values';

export function FooterBackToTop({ classes }) {
  const ref = useRef();
  const onBackToTopClick = e => {
    e.preventDefault();
    const venomAppTop = document.querySelector('.venom-app');
    if (venomAppTop) {
      venomAppTop.scrollIntoView({ behavior: 'smooth', block: 'start' });

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_RESET_NAVIGATION,
          subaction_name: TrackingConstant.JUMP_TO_TOP,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          synpartner: TrackingConstant.SYNPARTNER,
          action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
          creative_id: undefined,
          value: 'footer',
        },
      });
    }
  };
  useEffect(() => {
    const currentRef = ref.current;
    const onKeyDown = event => {
      const skipLink = document.querySelector('.skip-to-main-link');
      if (event.key === ENTER_KEY_VALUE && skipLink) {
        skipLink.focus();
      }
    };

    currentRef.addEventListener('keydown', onKeyDown, false);
    return () => {
      currentRef.removeEventListener('keydown', onKeyDown, false);
    };
  }, []);
  return (
    <a
      href="#main-content"
      className={classnames(
        'footer-to-top pos-r btn btn-outline-blue-30 fw-medium text-blue-30 px-1 py-0_5 mb-0_5 mb-md-0',
        classes
      )}
      rel="nofollow"
      onClick={onBackToTopClick}
      data-tracking-id="back_to_top"
      data-tracking-value="top"
      ref={ref}
    >
      Back to Top
      <i
        className="icon icon-arrow-up3 text-primary-darker size-16 ml-0_5"
        style={{ width: '1rem', height: '1rem' }}
        aria-hidden
      />
    </a>
  );
}

FooterBackToTop.propTypes = {
  classes: PropTypes.string,
};

FooterBackToTop.defaultProps = {
  classes: null,
};
